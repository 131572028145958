<template>
  <div class="page flex-col">
    <div class="group_11 flex-col">
      <div class="box_4 flex-row justify-between">
        <span class="text_3">{{ system }}</span>
        <span class="time">{{ time }}</span>
        <span class="day">{{ date }}</span>
        <span class="xq">{{ week }}</span>
      </div>
      <div class="box_8 flex-row">
        <div class="block_3 flex-col">
          <div class="box_9 flex-col">
            <div class="group_16 flex-col">
              <div class="box_10 flex-row">
                <div class="box_11 flex-col">
                  <img referrerpolicy="no-referrer"
                       src="../assets/screen/img/device.gif"
                       alt=""/>
                </div>
                <div class="box_12 flex-col">
                  <span class="text_4">{{ $t('panel.install') }}&nbsp;{{
                      screen.mount
                    }}&nbsp;&nbsp;{{ $t('panel.online') }}&nbsp;&nbsp;{{
                      screen.online
                    }}</span>
                  <img
                      class="image_7"
                      referrerpolicy="no-referrer"
                      src="../assets/screen/img/ps8myhk50x6yqmovi9cluk6r9ww1ukeudsa446d3310-af59-4444-b5db-f550f329eab9.png"
                      alt=""/>
                  <span class="text_5">{{ $t('panel.units') }}&nbsp;&nbsp;{{
                      screen.network
                    }}&nbsp;&nbsp;{{ $t('panel.offline') }}&nbsp;&nbsp;{{
                      screen.outline
                    }}</span>
                  <img
                      class="image_8"
                      referrerpolicy="no-referrer"
                      src="../assets/screen/img/psrde9r016hqleg75x9g5jnx1zlkyfuhsq380c35a0-1b9e-41a1-9087-d8c42b74de8b.png"
                      alt=""/>
                </div>
              </div>
              <div class="text-wrapper_1 flex-row"><span class="text_6">{{ $t('panel.health') }}</span></div>
              <div class="box_13 flex-row">
                <div class="section_8 flex-col device_health" ref="device_health">
                </div>
              </div>
            </div>
          </div>
          <img
              class="image_9"
              referrerpolicy="no-referrer"
              src="../assets/screen/img/psxffu426km3skg83eh4clgd48zyjx8yut4a1506fa1-a17a-47d2-985a-c67345cb921c.png"
              alt=""/>
        </div>
        <div class="block_4 flex-col">
          <div class="box_15 flex-col">
            <div class="map container" id="map" style="width:100%;height: 100%">
            </div>
          </div>
        </div>
        <div class="block_6 flex-col">
          <div class="group_24 flex-col">
            <div class="section_13 flex-row">
              <img
                  class="image_10"
                  referrerpolicy="no-referrer"
                  src="../assets/screen/img/ps6p1pxlrxdlsfamyr1k38kqrz22wse0eua33bb9035-6322-4b04-96e4-fc12b3c1a30c.png"
                  alt=""/>
              <div class="group_25 flex-col">
                <img
                    referrerpolicy="no-referrer"
                    src="../assets/screen/img/alarm.gif"
                    alt=""/>
              </div>
              <p class="paragraph_1">
                <span>{{ $t('panel.today') }}{{ screen.day_rate }}%</span><br/>
                <span>&nbsp;{{ screen.day_alarm }}&nbsp;&nbsp;{{ screen.day_handle_alarm }}</span>
              </p>
            </div>
            <div class="section_14 flex-row justify-between">
              <div class="box_21 flex-col">
                <img
                    referrerpolicy="no-referrer"
                    src="../assets/screen/img/users.gif"
                    alt=""/>
              </div>
              <span class="text_9">{{ $t('panel.new') }}&nbsp;&nbsp;{{ screen.day_user }}</span>
            </div>
            <div class="section_15 flex-row justify-between">
              <div class="group_28 flex-col justify-center">
              </div>
              <p class="text_11"><span>{{ $t('panel.accumulated') }}</span><br/><span>{{ screen.user }}</span></p>
            </div>
          </div>
        </div>

      </div>
      <div class="box_26 flex-col">
        <div class="text-wrapper_3 flex-row">
          <span class="text_12">{{ $t('panel.heart') }}（{{ 100 - screen.heart.rate }}%）</span>
          <span class="text_13">{{ $t('panel.daily_abnormal') }}</span>
          <span class="text_14">{{ $t('panel.product') }}</span>
          <span class="text_15">{{ $t('panel.active') }}</span>
        </div>
        <div class="section_16 flex-row">
          <div class="group_30 flex-col justify-center align-center">
            <div ref="heart" style="width: 100%;height: 100%"></div>
          </div>
          <div class="group_33 flex-col">
            <div
                :style="{width: '100%', height: '100%'}"
                ref="deviceStatus"
            ></div>
          </div>
          <div class="group_39 flex-col">
            <div
                :style="{width: '100%', height: '100%'}"
                ref="deviceType"
            ></div>
          </div>
          <div class="group_40 flex-col">
            <img
                class="image_12"
                referrerpolicy="no-referrer"
                src="../assets/screen/img/ps8ofmu04jqy9yix3pl0mos7so10wbsumd67a9053e-10a0-40eb-949a-3bc390ab7f9c.png"
                alt=""/>
            <div class="box_27 flex-col">
              <div class="water_order">
                <div class="water" :style="'top:' +(100-screen.active_rate)+'%;background: #34FDFF;'"></div>
              </div>
              <div class="box_28 flex-col">
                <div class="block_10 flex-col">
                  <div class="text-wrapper_5">
                    <span class="text_22">{{ screen.active_rate }}</span>
                    <span class="text_23">%</span>
                  </div>
                </div>
                <span class="text_24">{{ screen.active_user }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="section_17 flex-row">
          <div class="box_29 flex-col justify-between">
            <span class="text_25">{{ $t('panel.blood') }}（{{ 100 - screen.blood.rate }}%）</span>
            <div class="section_18 flex-col">
              <div
                  :style="{width: '100%', height: '100%'}"
                  ref="deviceBlood"
              ></div>
            </div>
          </div>
          <div class="box_30 flex-col justify-between">
            <span class="text_26">{{ $t('panel.oxygen') }}（{{ 100 - screen.oxygen.rate }}%）</span>
            <div class="box_31 flex-col">
              <div
                  :style="{width: '100%', height: '100%'}"
                  ref="deviceOxygen"
              ></div>
            </div>
          </div>
          <div class="box_34 flex-col">
            <span class="text_33">{{ $t('panel.monthly') }}</span>
            <div class="box_35 flex-row justify-between">
              <div
                  :style="{width: '100%', height: '100%'}"
                  ref="monthHealth"
              ></div>
            </div>
          </div>
          <div class="box_38 flex-col justify-between">
            <span class="text_37">{{ $t('panel.temp') }}（{{ 100 - screen.temp.rate }}%）</span>
            <div class="box_39 flex-col">
              <div class="group_41 flex-col"></div>
              <div class="group_42 flex-col">
                <div class="section_21 flex-col">
                  <div class="water_order">
                    <div class="water" :style="'top:' +(screen.temp.rate)+'%;background: #E5E5E5;'"></div>
                  </div>
                  <div class="box_40 flex-col">
                    <div class="text-wrapper_8">
                      <span class="text_38">{{ 100 - screen.temp.rate }}</span>
                      <span class="text_39">%</span>
                    </div>
                  </div>
                </div>
                <span class="text_40">{{ screen.temp.handle }}</span>
              </div>
            </div>
          </div>
          <div class="box_41 flex-col justify-between">
            <span class="text_41">{{ $t('panel.sleep') }}（{{ 100 - screen.sleep.rate }}%）</span>
            <div class="group_43 flex-col">
              <div class="block_11 flex-col"></div>
              <div class="block_12 flex-col">
                <div class="section_22 flex-col">
                  <div class="water_order">
                    <div class="water" :style="'top:' +(100-screen.sleep.rate)+'%;background: #F8B551;'"></div>
                  </div>
                  <div class="group_44 flex-col">
                    <div class="text-wrapper_9">
                      <span class="text_42">{{ screen.sleep.rate }}</span>
                      <span class="text_43">%</span>
                    </div>
                  </div>
                </div>
                <span class="text_44">{{ screen.sleep.handle }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import data from "@/model/data";

function init() {
  const _el = document.getElementById('app');
  const hScale = window.innerHeight / 1080;
  const wScale = window.innerWidth / 1920;
  _el.style.transform = 'scale(' + wScale + ',' + hScale + ')'
}

init();
// 将事件侦听器函数附加到窗口的resize事件
window.addEventListener("resize", init);
import echarts from '../utils/echarts'
import AMapLoader from "@amap/amap-jsapi-loader";
import tool from "@/utils/tool";


let map;
export default {
  data() {
    return {
      screen: {
        heart: {},
        blood: {},
        oxygen: {},
        temp: {},
        sleep: {},
      },
      time: '',
      date: '',
      week: '',
      mapData: {},
      system: ''
    };
  },
  computed: {
    alarmImei() {
      this.mapData.map.forEach(v => {
        if (v.imei === this.$store.state.alarm_imei) {
          this.handleInfo(v)
        }
      })
      return this.$store.state.alarm_imei
    }
  },
  mounted() {
    // this.loadMapJs()
    this.loadData()
    this.loadMapData()
    this.system = tool.isEmpty(tool.getLocalStorage("client_name")) ? '智能手环管理系统' : tool.getLocalStorage("client_name")
  },
  methods: {
    async loadData() {
      const ret = await data.screen({})
      this.screen = ret.data
      this.loadDeviceHealth()
      this.loadDeviceHeart()
      this.loadDeviceBlood()
      this.loadDeviceOxygen()
      this.loadDeviceType()
      this.handleTime()
      setInterval(() => {
        this.handleTime()
      }, 1000)
    },
    async loadMapData() {
      const ret = await data.map({})
      this.mapData = ret.data
      this.loadDeviceStatus()
      this.loadDeviceMonth()
      setTimeout(() => {
        this.loadMap()
      }, 2500)
    },
    handleTime() {
      const date = new Date(this.screen.time * 1000);
      const YY = date.getFullYear() + '-';
      const MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      const DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
      const hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      const mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
      const ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
      this.date = YY + MM + DD
      this.time = hh + mm + ss
      switch (date.getDay()) {
        case 0:
          this.week = this.$t('device.sunday');
          break;
        case 1:
          this.week = this.$t('device.monday');
          break;
        case 2:
          this.week = this.$t('device.tuesday');
          break;
        case 3:
          this.week = this.$t('device.wednesday');
          break;
        case 4:
          this.week = this.$t('device.thursday');
          break;
        case 5:
          this.week = this.$t('device.friday');
          break;
        case 6:
          this.week = this.$t('device.saturday');
          break;
      }
      this.screen.time++
    },
    async loadMap() {
      const AMap = await AMapLoader.load({
        key: this.$store.state.amap_key, // 申请好的Web端开发者Key，首次调用 load 时必填
        version: this.$store.state.amap_version, // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [] //插件列表
      })
      map = new AMap.Map("map", {
        zoom: 4,
        showLabel: false,
        viewMode: '3D',
        mapStyle: 'amap://styles/blue'
      })
      let trafficLayer = new AMap.TileLayer.Traffic({
        zIndex: 2
      })
      let marker = []
      this.mapData.map.forEach(v => {
        if (v.location_time) {
          let m = new AMap.Marker({
            icon:
                v.is_online !== 1
                    ? this.$store.state.outline
                    : this.$store.state.online,
            position: [v.longitude, v.latitude],
            title: v.imei
          })
          marker.push(m)
          m.on("click", e => {
            this.mapData.map.forEach(v => {
              if (v.imei === e.target.De.title) {
                this.handleInfo(v)
              }
            })
          })
        }
      })
      map.setFitView();
      // 将以上覆盖物添加到地图上
      // 单独将点标记添加到地图上
      map.add(marker)
      map.add(trafficLayer) //添加图层到地图
      map.plugin(["AMap.MarkerClusterer"], function () {
        new AMap.MarkerClusterer(map, marker)
      })
    },
    handleInfo(device) {
      const title = 'IMEI：<a href="/data/home?imei=' + device.imei + '">' + device.imei + '</a>' +
              '<span style="font-size:11px;color:#F00;">' + this.$t('index.device_type') + ':' + device.type_title + '</span>',
          content = []
      content.push(this.$t('panel.location') + "：" + device.location)
      content.push("<br/>" + this.$t('device.position_time') + "：" + device.location_time)
      content.push("<br/>" + this.$t('device.device_status') + "：" + device.online)
      content.push("<br/>" + this.$t('index.online_status') + "：" + device.volt)
      content.push("<br/>" + this.$t('panel.final') + "：" + (tool.isEmpty(device.alarm_content) ? '无' : device.alarm_content))
      content.push("<br/>" + this.$t('panel.last_time') + "：" + (tool.isEmpty(device.alarm_time) ? '无' : device.alarm_time))
      const infoWindow = new AMap.InfoWindow({
        isCustom: true,
        offset: new AMap.Pixel(16, -45),
        content: this.createInfoWindow(title, content.join(''))
      })
      infoWindow.open(map, [device.longitude, device.latitude])
    },
    createInfoWindow(title, content) {
      const info = document.createElement("div")
      info.className = "custom-info input-card content-window-card"

      //可以通过下面的方式修改自定义窗体的宽高
      info.style.width = "500px"
      // 定义顶部标题
      const top = document.createElement("div")
      const titleD = document.createElement("div")
      const closeX = document.createElement("img")
      top.className = "info-top"
      titleD.innerHTML = title
      closeX.src = "https://webapi.amap.com/images/close2.gif"
      closeX.onclick = this.closeInfoWindow

      top.appendChild(titleD)
      top.appendChild(closeX)
      info.appendChild(top)

      // 定义中部内容
      const middle = document.createElement("div")
      middle.className = "info-middle"
      middle.style.backgroundColor = 'white'
      middle.style.fontSize = '15px'
      middle.style.paddingLeft = '20px'
      middle.style.paddingRight = '20px'
      middle.innerHTML = content
      info.appendChild(middle)

      // 定义底部内容
      const bottom = document.createElement("div")
      bottom.className = "info-bottom"
      bottom.style.position = 'relative'
      bottom.style.top = '0px'
      bottom.style.margin = '0 auto'
      const sharp = document.createElement("img")
      sharp.src = "https://webapi.amap.com/images/sharp.png"
      bottom.appendChild(sharp)
      info.appendChild(bottom)
      return info
    },
    closeInfoWindow() {
      map.clearInfoWindow()
    },
    loadDeviceHealth() {
      let myEchart = echarts.init(this.$refs.device_health)
      const option = {
        tooltip: {
          trigger: "item",
          formatter: "{b}:{c}",

        },
        series: [
          {
            // name: "访问来源",
            type: "pie",
            radius: ["55%", "80%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            emphasis: {
              label: {
                show: true,
                fontSize: "22",
                fontWeight: "bold"
              }
            },
            label: {                        // 饼图图形上的文本标签，可用于说明图形的一些数据信息，比如值，名称等.
              show: true,             // 是否显示标签[ default: false ]
              position: 'outside',    // 标签的位置。'outside'饼图扇区外侧，通过视觉引导线连到相应的扇区。'inside','inner' 同 'inside',饼图扇区内部。'center'在饼图中心位置。
              formatter: '{b} : {c}',  // 标签内容
              fontSize: '16'
            },
            labelLine: {                    // 标签的视觉引导线样式,在 label 位置 设置为'outside'的时候会显示视觉引导线。
              show: true,             // 是否显示视觉引导线。
              length: 15,             // 在 label 位置 设置为'outside'的时候会显示视觉引导线。
              length2: 10,            // 视觉引导项第二段的长度。
              lineStyle: {            // 视觉引导线的样式
                //color: '#000',
                //width: 1
              }
            },
            data: [
              {
                value: this.screen.normal,
                name: this.$t('panel.normal'),
                itemStyle: {color: "#014DAD"}
              },
              {
                value: this.screen.alarm,
                name: this.$t('panel.alarm'),
                itemStyle: {color: "#BA54E4"}
              }
            ]
          }
        ]
      };
      myEchart.setOption(option);
    },
    loadDeviceStatus() {
      const myEchart = echarts.init(this.$refs.deviceStatus);
      const option = {
        color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        grid: {
          left: '1%',
          right: '5%',
          bottom: '10%',
          top: '5%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLine: {
              onZero: false,
              show: true,
              lineStyle: {
                color: ['#4e8ab1'],
                width: 2,
                type: 'solid'
              }
            },
            axisLabel: {
              formatter: '{value}',
              textStyle: {
                color: "#fff",
                fontSize: 16
              }
            },
            data: this.mapData.day_date
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              formatter: '{value}',
              textStyle: {
                color: "#fff",
                fontSize: 20,
              }
            },
            axisLine: {
              onZero: false,
              show: true,
              lineStyle: {
                color: ['#4e8ab1'],
                width: 2,
                type: 'solid'
              }
            },
          }
        ],
        series: [
          {
            name: this.$t('panel.abnormal_number'),
            type: 'line',
            stack: 'Total',
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(128, 255, 165)'
                },
                {
                  offset: 1,
                  color: 'rgb(1, 191, 236)'
                }
              ])
            },
            emphasis: {
              focus: 'series'
            },
            data: this.mapData.day_number
          }
        ]
      };
      myEchart.setOption(option);
    },
    loadDeviceType() {
      const myEchart = echarts.init(this.$refs.deviceType);
      const option = {
        tooltip: {
          trigger: "item",
          formatter: "{b}:{c}"
        },
        grid: {
          left: '1%',
          right: '1%',
          bottom: '1%',
          top: '5%',
          containLabel: true
        },
        graphic: [
          { //环形图中间添加文字
            type: 'text', //通过不同top值可以设置上下显示
            left: 'center',
            top: '30%',
            style: {
              text: this.screen.device,
              textAlign: 'center',
              fill: '#FF4A53', //文字的颜色
              width: 30,
              height: 30,
              fontSize: 24
            }
          },
          { //环形图中间添加文字
            type: 'text', //通过不同top值可以设置上下显示
            left: 'center',
            top: '50%',
            style: {
              text: this.$t('panel.bracelet'),
              textAlign: 'center',
              fill: '#E5EAFB', //文字的颜色
              width: 30,
              height: 30,
              fontSize: 16
            }
          }
        ],
        series: [
          {
            type: "pie",
            radius: ["70%", "80%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            labelLine: {
              show: false
            },
            data: [
              {
                value: this.screen.device,
                name: this.$t('panel.bracelet'),
                itemStyle: {color: "#206ED4"}
              },
              {
                value: 0,
                name: this.$t('panel.door_lock'),
                itemStyle: {color: "#00DEE9"}
              }
            ]
          }
        ]
      };
      myEchart.setOption(option);
    },
    loadDeviceHeart() {
      let myEchart = echarts.init(this.$refs.heart);
      const option = {
        tooltip: {
          trigger: "item",
          formatter: "{b}:{c}"
        },
        graphic: [
          { //环形图中间添加文字
            type: 'text', //通过不同top值可以设置上下显示
            left: '35%',
            top: '30%',
            style: {
              text: this.screen.heart.rate + '%',
              textAlign: 'center',
              fill: '#FF4A53', //文字的颜色
              width: 30,
              height: 30,
              fontSize: 24
            }
          },
          { //环形图中间添加文字
            type: 'text', //通过不同top值可以设置上下显示
            left: '31%',
            top: '50%',
            style: {
              text: this.$t('panel.unprocessed'),
              textAlign: 'center',
              fill: '#E5EAFB', //文字的颜色
              width: 30,
              height: 30,
              fontSize: 16
            }
          }
        ],
        series: [
          {
            // name: "访问来源",
            type: "pie",
            radius: ["70%", "80%"],
            center: ["40%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            labelLine: {
              show: false
            },
            data: [
              {
                value: this.screen.heart.handle,
                name: this.$t('panel.processed'),
                itemStyle: {color: "#FF4A53"}
              },
              {
                value: this.screen.heart.number,
                name: this.$t('panel.untreated'),
                itemStyle: {color: "#00DEE9"}
              }
            ]
          }
        ]
      };
      myEchart.setOption(option);
    },
    loadDeviceBlood() {
      let myEchart = echarts.init(this.$refs.deviceBlood);
      const option = {
        tooltip: {
          trigger: "item",
          formatter: "{b}:{c}"
        },
        graphic: [
          { //环形图中间添加文字
            type: 'text', //通过不同top值可以设置上下显示
            left: 'center',
            top: '30%',
            style: {
              text: this.screen.blood.rate + '%',
              textAlign: 'center',
              fill: '#FF4A53', //文字的颜色
              width: 30,
              height: 30,
              fontSize: 24
            }
          },
          { //环形图中间添加文字
            type: 'text', //通过不同top值可以设置上下显示
            left: 'center',
            top: '50%',
            style: {
              text: this.$t('panel.unprocessed'),
              textAlign: 'center',
              fill: '#E5EAFB', //文字的颜色
              width: 30,
              height: 30,
              fontSize: 16
            }
          }
        ],
        series: [
          {
            // name: "访问来源",
            type: "pie",
            radius: ["60%", "70%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            labelLine: {
              show: false
            },
            data: [
              {
                value: this.screen.blood.handle,
                name: this.$t('panel.processed'),
                itemStyle: {color: "#FFFF00"}
              },
              {
                value: this.screen.blood.number,
                name: this.$t('panel.untreated'),
                itemStyle: {color: "#00DEE9"}
              }
            ]
          }
        ]
      };
      myEchart.setOption(option);
    },
    loadDeviceOxygen() {
      let myEchart = echarts.init(this.$refs.deviceOxygen);
      const option = {
        tooltip: {
          trigger: "item",
          formatter: "{b}:{c}"
        },
        graphic: [
          { //环形图中间添加文字
            type: 'text', //通过不同top值可以设置上下显示
            left: 'center',
            top: '30%',
            style: {
              text: this.screen.oxygen.rate + '%',
              textAlign: 'center',
              fill: '#FF4A53', //文字的颜色
              width: 30,
              height: 30,
              fontSize: 24
            }
          },
          { //环形图中间添加文字
            type: 'text', //通过不同top值可以设置上下显示
            left: 'center',
            top: '50%',
            style: {
              text: this.$t('panel.unprocessed'),
              textAlign: 'center',
              fill: '#E5EAFB', //文字的颜色
              width: 30,
              height: 30,
              fontSize: 16
            }
          }
        ],
        series: [
          {
            // name: "访问来源",
            type: "pie",
            radius: ["60%", "70%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            labelLine: {
              show: false
            },
            data: [
              {
                value: this.screen.oxygen.handle,
                name: this.$t('panel.processed'),
                itemStyle: {color: "#22AC38"}
              },
              {
                value: this.screen.oxygen.number,
                name: this.$t('panel.untreated'),
                itemStyle: {color: "#00DEE9"}
              }
            ]
          }
        ]
      };
      myEchart.setOption(option);
    },
    loadDeviceMonth() {
      let myEchart = echarts.init(this.$refs.monthHealth);
      const option = {
        xAxis: {
          type: 'category',
          data: this.mapData.month,
          axisLabel: {
            formatter: '{value}',
            textStyle: {
              color: "#fff",
              fontSize: 16,
            }
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}',
            textStyle: {
              color: "#fff",
              fontSize: 16,
            }
          },
        },
        grid: {
          left: '1%',
          right: '1%',
          bottom: '10%',
          top: '10%',
          containLabel: true
        },
        series: [
          {
            data: this.mapData.month_number,
            type: 'bar'
          }
        ]
      };
      myEchart.setOption(option);
    },
    loadMapJs() {
      const mapJs = document.createElement('script');
      mapJs.src = 'https://webapi.amap.com/maps?v=2.0&key=b6546fb871bbf2543f413ce60f31f0bb&plugin=AMap.Scale,AMap.ToolBar';
      setTimeout(() => {
        const locaJs = document.createElement('script');
        locaJs.src = 'https://webapi.amap.com/loca?v=2.0.0&key=b6546fb871bbf2543f413ce60f31f0bb';
        document.body.appendChild(locaJs);
      }, 1000)
      const eventJs = document.createElement('script');
      eventJs.src = 'https://a.amap.com/Loca/static/loca-v2/demos/mock_data/events.js';
      document.body.appendChild(mapJs);
      document.body.appendChild(eventJs);
    }
  }
};
</script>
<style scoped lang="css" src="../assets/screen/common.css"/>
<style scoped lang="css" src="../assets/screen/index.css"/>
<style>
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 14px;
  overflow: hidden !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  transform-origin: 0 0;
  width: 1920px !important;
  height: 1080px !important;
}
</style>
